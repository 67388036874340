<template>
  <div class="application">
    <!-- kv -->
    <section
      id="kv_content"
      :style="{ backgroundImage: `url( ${require('@/statics/img/banner/apply.png')} )` }"
    >
      <div class="kv_title txt-white">
        <h1>APPLICATION</h1>
        <h2>入會申請</h2>
      </div>
    </section>

    <!-- current stage -->
    <section
      id="stage"
      :style="{ backgroundImage: `url( ${require('@/statics/img/index/kv_bg.png')} )` }"
    >
      <div class="w1300">

        <ApplicationStage />

      </div>
    </section>

    <main>
      <section id="application_verify">
        <div class="w1000">

          <!-- form area -->
          <div class="form_box">
            <form id="form_application_verify" action="" method="post" @submit.prevent>
              <div class="form_description">
                <p>
                  <span id="user_name">{{ realname }}</span> 您好，<br><br>
                  <span class="txt-bold">
                    您的資料審核狀態為：
                    <span class="txt-red">{{ applyStatus }}</span>
                  </span><br>
                  <span v-show="applyStatus === '未通過'">備註：醫師證書影本，請再次上傳資料。</span>
                </p>
              </div>
              <div class="form_interactive">
                <Upload
                  class="w100"
                  :class="{ disabled: applyStatus === '待審核' }"
                  v-model="user.files.attachment"
                  :item="inputFormat.attachmentUpload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                />
              </div>

              <div class="form_submit txt-center">
                <button
                  type="button"
                  class="btn"
                  :class="{ disabled: applyStatus === '待審核' }"
                  @click="onSubmit"
                >送出</button>
              </div>
            </form>
          </div>

        </div>
      </section>
    </main>
  </div>
</template>

<script>
import '@/assets/scss/application.scss';

import ApplicationStage from '@/components/ApplicationStage.vue';
import Upload from '@/components/form/Upload.vue';
import { request, toFormData } from '@/boot/axios';
import { mapGetters } from 'vuex';
// eslint-disable-next-line no-unused-vars
import { userInfo, idVerify } from '@/lib/http';

export default {
  title: '入會申請',
  name: 'ApplicationVerify',
  data() {
    return {
      user: {
        files: {
          attachment: '',
        },
      },
      inputFormat: {
        attachmentUpload: {
          label: '附檔上傳',
          reminder: '(格式 jpg/png/pdf。限制大小：1MB)',
          accept: '.jpg,.png,.pdf',
          required: true,
        },
      },
      realname: '註冊會員',
      applyStatus: '',
    };
  },
  created() {
    if (this.hasToken) {
      userInfo().then((result) => {
        this.realname = result.realname;
        // eslint-disable-next-line no-unused-vars
        idVerify({ id_number: result.id_number }).then((res) => {
          // console.log(res);

          /** 判斷審核中 / 補件 / 通過 */
        });
      });
    }

    const verifyStatus = this.$route.query.status * 1;

    switch (verifyStatus) {
      case -1:
        this.applyStatus = '未通過';
        break;
      default:
        this.applyStatus = '待審核';
        break;
    }
  },
  computed: {
    ...mapGetters([
      'hasToken',
    ]),
  },
  methods: {
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        request({
          url: 'API',
          method: 'post',
          data: toFormData(this.user),
        });
      } else {
        // console.log('必填欄位尚未完成！');
      }
    },
  },
  components: {
    ApplicationStage,
    Upload,
  },
};
</script>
